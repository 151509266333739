<template>
  <v-container fluid pa-0 pt-6 pb-6 fill-height align-stretch>
    <v-row>
      <v-col cols="12" lg="8" class="pgi-col-left d-flex">
        <v-row align="center">
          <v-col cols="12" lg="6" offset-lg="1">
            <v-img
              contain
              max-width="350"
              src="@/assets/images/pg-insights-logo-r.svg"
            ></v-img>
            <p class="mt-6 text-body-1">
              PlanGap is pioneering a new category of financial solutions-
              retirement insurance. In partnership with innovative financial
              institutions, we’ve brought to market the first ever Social
              Security strategy that pays a bonus should Social Security
              benefits be reduced as a result of government action. PlanGap
              Insights brings a next-level suite of intelligent and predictive
              data to help insurers and reinsurers make informed decisions.
            </p>
            <p class="mt-6 text-h6 teal--text text--accent-3">
              Informed Decisions.
              <span class="teal--text text--accent-2">Robust Planning. </span>
              <span class="teal--text text--accent-1">More Control.</span>
            </p>
            <v-list flat color="transparent">
              <v-list-item v-for="item in items" :key="item.id">
                <v-list-item-icon>
                  <v-icon color="teal">mdi-check-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap"
                    v-text="item.content"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <div class="mt-6 ">
              <v-btn to="/access" x-large color="ma-2 teal darken-2">
                Request Access
              </v-btn>

              <v-btn to="/login" x-large color="ma-2 primary darken-2">
                Account Login
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="4" class="d-none d-lg-block pgi-right">
        <!-- <img class="pgi-dash-image" src="@/assets/images/pgi-dashboard.png" /> -->
        <video
          class="pgi-dash-image"
          muted
          autoplay
          loop
          disablePictureInPicture
          disableRemotePlayback
          playsinline
          preload="auto"
          controlslist="nodownload nofullscreen"
          poster="@/assets/dashboardDemo/pgi-dashboard-poster.png"
        >
          <source
            src="@/assets/dashboardDemo/pgi-dash-demo.mp4"
            type="video/mp4"
          />
          <source
            src="@/assets/dashboardDemo/pgi-dash-demo.webm"
            type="video/webm"
          />
          <source
            src="@/assets/dashboardDemo/pgi-dash-demo.ogv"
            type="video/ogg"
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showAccessDialog"
      max-width="600"
      light
      persistent
      overlay-opacity="0.9"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you an Insurance Carrier?
        </v-card-title>

        <v-card-text>
          PlanGap Insights is an enterprise application for insurance carriers.
          Those who are not associated with an insurance carrier may visit
          <a class="teal--text text--accent" href="https://plangap.com"
            >PlanGap.com</a
          >
          for more information about retirement insurance.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal darken-2"
            class="ma-4"
            href="https://plangap.com"
            text
          >
            Go to PlanGap.com
          </v-btn>

          <v-btn color="" text @click="showAccessDialog = false">
            Insurance Carrier
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showAccessDialog: true,
      items: [
        {
          id: 111,
          content: "Customizable dashboard and calculators",
        },
        {
          id: 222,
          content: "Access Secure PlanGap and Index APIs",
        },
        {
          id: 333,
          content: "Predictive simulations based on legislative events",
        },
        {
          id: 444,
          content: "Manage Index alerts and notifications",
        },
        {
          id: 555,
          content: "Trend analysis and probability",
        },
        {
          id: 667,
          content: "Access enhanced demographic breakdowns",
        },
        {
          id: 777,
          content: "Interactive data visualizations",
        },
        {
          id: 888,
          content: "In-depth Social Security news and analysis",
        },
        {
          id: 999,
          content: "Ability to generate and share reports",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.pgi-col-left {
  //background: #141b28;
  background: linear-gradient(to left, #171a25, rgba(#171a25, 0));
  /* background: linear-gradient(to left, #eee 75%, #333 75%); */
}

.pgi-dash-image {
  position: absolute;
  top: 50%;
  right: -20%;
  width: 75%;
  transform: translateY(-50%);
  border-radius: 5px;

  box-shadow: inset 0 1px 1px 0 rgb(122 0 255),
    0 50px 100px -20px rgb(0 188 212 / 12%),
    0 30px 60px -30px rgb(33 150 243 / 6%);
}

.v-list-item__icon {
  margin: 12px 0;
}
</style>
